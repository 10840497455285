// updated authorization.tsx
import appConfig from './app-config';
import { adClient } from './http-client';
import { useMsal } from '@azure/msal-react';

export function getClaims() {
   let account = adClient.getActiveAccount();
   if (account == null) {
      const accounts = adClient.getAllAccounts();
      if (accounts && accounts.length > 0) {
         account = accounts[0];
      }
   }
   if (!account) {
      return null;
   }
   return account.idTokenClaims;
}

export function getClaimName(): string {
   const claims = getClaims();
   if (claims == null || claims.name == null) {
      return '';
   } else {
      return claims.name;
   }
}

export function getClaimFirstName(): string {
   const claims = getClaims();
   if (claims == null || claims['given_name'] == null) {
      return '';
   } else {
      return claims['given_name'].toString();
   }
}

export function getClaimLastName(): string {
   const claims = getClaims();
   if (claims == null || claims['family_name'] == null) {
      return '';
   } else {
      return claims['family_name'].toString();
   }
}

export function getClaimEmail(): string {
   const claims = getClaims();
   if (claims == null || claims.emails == null) {
      return '';
   } else {
      return claims.emails[0];
   }
}

export function isAdmin(): boolean {
   const claims = getClaims();
   if (claims == null || claims['extension_AppRoles'] == null) {
      return false;
   } else {
      return claims['extension_AppRoles'].toString().includes('AppAdmin');
   }
}

export function isAffiliate(): boolean {
   const { instance: adInstance } = useMsal();
   const claims = getClaims();

   if (claims && !claims['extension_AppRoles']) {
      adInstance.logoutRedirect({
         postLogoutRedirectUri: process.env.MSAL_REDIRECT_URI,
      });
   }
   
   if (claims == null || claims['extension_AppRoles'] == null) {
      return false;
   } else {
      return claims['extension_AppRoles'].toString().includes('Affiliate');
   }
}

export function login(loginType: 'popup' | 'redirect') {
   const loginRequest = {
      scopes: [appConfig.auth.scopes.user],
      extraQueryParameters: {
         prompt: "login",
      },
   };
   if (loginType === 'popup') {
      adClient
         .loginPopup(loginRequest)
         .then((r) => {
            adClient.setActiveAccount(r.account);
         })
         .catch((e) => {
            // TODO: error handling
            console.log(e);
         });
   } else if (loginType === 'redirect') {
      adClient.loginRedirect(loginRequest).catch((e) => {
         // TODO: error handling
         console.log(e);
      });
   }
}

export function logout(logoutType: 'popup' | 'redirect') {
   if (logoutType === 'popup') {
      adClient.logoutPopup({
         postLogoutRedirectUri: '/',
         mainWindowRedirectUri: '/',
      });
   } else if (logoutType === 'redirect') {
      adClient.logoutRedirect({
         postLogoutRedirectUri: '/',
      });
   }
}




