import React from 'react';
import { useRecoilValue } from 'recoil';
import {
   getClaimEmail,
   getClaimFirstName,
   getClaimLastName,
} from '../../lib/authorization';
import { IAffiliateImpersonation } from '../../lib/types';
import { adminImpersonateCookie } from '../../recoil/admin-impersonate';
import { affiliateAtom } from '../../recoil/affiliate';
import { loadSelector } from '../../recoil/load';
import ClaimsProfileContent from '../claims-profile-content/claims-profile-content';
import './profile-content.scss';
import { employeeInfoAtom } from '../../recoil/employee-info';

export default function ProfileContent(): JSX.Element {
   const emailDisabledSvg = new URL(
      '/public/images/email-disabled.svg',
      import.meta.url
   );

   const contactDisabledSvg = new URL(
      '/public/images/contact-disabled.svg',
      import.meta.url
   );

   const locationDisabledSvg = new URL(
      '/public/images/location-disabled.svg',
      import.meta.url
   );

   const affiliate = useRecoilValue(affiliateAtom);
   const employees = useRecoilValue(employeeInfoAtom);
   const load = useRecoilValue(loadSelector);
   const cookie: IAffiliateImpersonation | null = useRecoilValue(
      adminImpersonateCookie
   );

   if (!affiliate) {
      return <ClaimsProfileContent />;
   }

   // Check if userId is present
   const isEmployeeView = !!load?.userId;

   // Find employee with matching userId
   const matchedEmployee = employees.find(emp => emp.id === load?.userId);

   return (
      <div className="profile-content">
         <div className="affiliate-label body-standard-regular">
            {isEmployeeView ? matchedEmployee?.roleName : affiliate.title}
         </div>
         <div className="affiliate-name h1-headline-regular">
            {cookie
               ? `${affiliate.firstName} ${affiliate.lastName}`
               : `${getClaimFirstName()} ${getClaimLastName()}`}
         </div>
         <div className="personal-label label-xsmall-allcaps">personal</div>

         {/* Email Section */}
         <div className="email-wrapper">
            <img src={emailDisabledSvg.toString()} />
            <div className="profile-email body-standard-regular">
               {cookie ? affiliate.email : getClaimEmail()}
            </div>
         </div>

         {/* Contact Section (Shows Employee Contact if userId exists and matches) */}
         <div className="contact-wrapper">
            <img src={contactDisabledSvg.toString()} />
            <div className="profile-contact body-standard-regular">
               {isEmployeeView 
                  ? matchedEmployee?.contactNo || '-' 
                  : affiliate.phone || '-'}
            </div>
         </div>

         {/* Hide Address Wrapper & Firm Info when userId exists */}
         {!isEmployeeView && (
            <>
               <div className="address-wrapper">
                  <img src={locationDisabledSvg.toString()} />
                  <div className="address-text-wrapper">
                     <div className="profile-address-1 body-standard-regular">
                        {affiliate.address +
                           (affiliate.address2 ? ` ${affiliate.address2}` : '')}
                     </div>
                     <div className="profile-address-2 body-standard-regular">
                        {`${affiliate.city}, ${affiliate.state} ${affiliate.zip}`}
                     </div>
                  </div>
               </div>

               <div className="firm-label label-xsmall-allcaps">firm</div>
               <div className="firm-wrapper">
                  <div className="profile-firm-label body-standard-regular">
                     Firm
                  </div>
                  <div className="profile-firm body-standard-regular">
                     {affiliate.organizationName}
                  </div>
               </div>

               <div className="city-wrapper">
                  <div className="profile-city-label body-standard-regular">
                     City
                  </div>
                  <div className="profile-city body-standard-regular">
                     {affiliate.organizationCity}
                  </div>
               </div>

               <div className="state-wrapper">
                  <div className="profile-state-label body-standard-regular">
                     State
                  </div>
                  <div className="profile-state body-standard-regular">
                     {affiliate.organizationState}
                  </div>
               </div>
            </>
         )}

         <div className="profile-help-text body-small-regular">
            Please call intoxalock at{' '}
            <span className="profile-help-number">617-283-1291</span> to manage
            or update your profile information.
         </div>
      </div>
   );
}
