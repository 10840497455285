import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFirmInfo } from '../../lib/types';
import './firm-details.scss';
import api from '../../lib/api';

export interface IFirmInfoProps {
   firm: IFirmInfo;
}

export default function FirmDetails(props: IFirmInfoProps): JSX.Element {
   const navigate = useNavigate();
   const [isClicked, setIsClicked] = useState(false);
   const [redirect, setRedirect] = useState(false);

   const { organizationName } = props.firm;

   const getAffiliateDetails = useCallback((): void => {
      if (!isClicked && organizationName) {
         setIsClicked(true);  // Mark as clicked to prevent subsequent calls
         setRedirect(true);    // Set redirect to true for useEffect to handle navigation

         // Store in session storage
         sessionStorage.setItem('impersonatedOrgName', organizationName);
      }
   }, [isClicked, organizationName]);

   useEffect(() => {
      if (redirect) {
         const encodedOrganizationName = encodeURIComponent(organizationName);
         navigate(`/admin/${encodedOrganizationName}`);
         setRedirect(false); // Reset redirect after navigating
      }
   }, [redirect, navigate, organizationName]);

   return (
       <div
         className={'card-affiliate'}
         onClick={getAffiliateDetails}
      >
         <div className="card-affiliate-name">
            <div className="affiliate-header-wrapper">
               <div className="affiliate-type body-standard-regular">
                  Firm
               </div>
            </div>
            <div className="affiliate-name h1-headline-regular text-ellipsis" title={props.firm.organizationName}>
               {props.firm.organizationName}
            </div>
         </div>
         
         <div className="firm-associate-wrapper">
            <div className="card-affiliate-firm">
               <div className="card-affiliate-firm-name body-standard-regular">
                  Affiliates
               </div>
               <div className="card-affiliate-firm-name h1-headline-regular">
                  {props.firm.affiliateCount}
               </div>
            </div>
            <div className="card-affiliate-firm">
               <div className="card-affiliate-firm-name body-standard-regular">
                  Clients
               </div>
               <div className="card-affiliate-firm-name h1-headline-regular">
                  {props.firm.customerCount}
               </div>
            </div>
         </div>
      </div>
   );
}
