import React, { useEffect } from 'react';
import {
   useRecoilRefresher_UNSTABLE,
   useRecoilState,
   useRecoilValue,
} from 'recoil';
import api from '../../lib/api';
import { ICustomer, ICustomerRecent, IEmployee } from '../../lib/types';
import { affiliateAtom } from '../../recoil/affiliate';
import { customersArchiveAtom } from '../../recoil/customer-archive';
import {
   customerRecentSelector,
   reloadRecentCustomers,
} from '../../recoil/customer-recent';
import {
   customersSearchQueryAtom,
   searchedCustomersSelector,
} from '../../recoil/customer-search';
import { customersAtom } from '../../recoil/customers';
import { filteredSortedCustomersSelector } from '../../recoil/customers-sort-filter';
import CardCustomer from '../card-customer/card-customer';
import CheckboxText from '../checkbox-text/checkbox-text';
import InviteButton from '../invite-button/invite-button';
import Search from '../search/search';
import SortFilter from '../sort-filter/sort-filter';
import './affiliate-attorney.scss';
import EmployeeSort from '../employee-sort-filter/employee-sort-filter';
import {
   employeesSearchQueryAtom,
   searchedEmployeesSelector,
} from '../../recoil/employee-search';
import { filteredEmployeeSelector } from '../../recoil/employee-sort-filter';
import CardEmployee from '../card-employee/card-employee';
import GeneralSearch from '../general-search/general-search';
import { loadSelector } from '../../recoil/load';

export default function AffiliateAttorney(): JSX.Element {
   const [activeTab, setActiveTab] = React.useState<'Employees' | 'Clients'>(
      'Clients',
   );
   const [searchQuery, setSearchQuery] = useRecoilState(
      employeesSearchQueryAtom,
   );
   const searchResults = useRecoilValue(searchedEmployeesSelector);
   const load = useRecoilValue(loadSelector);

   const filteredSortedList: ICustomer[] = useRecoilValue(
      filteredSortedCustomersSelector,
   );
   const filteredSortedEmployeeList: IEmployee[] = useRecoilValue(
      filteredEmployeeSelector,
   );
   const customers = useRecoilValue(customersAtom);
   const affiliate = useRecoilValue(affiliateAtom);

   const customerRecents: ICustomerRecent[] = useRecoilValue(
      customerRecentSelector(affiliate?.id),
   );
   const customerRecentRefresh = useRecoilRefresher_UNSTABLE(
      customerRecentSelector(affiliate?.id),
   );
   const [reloadRecent, setReloadRecent] = useRecoilState(
      reloadRecentCustomers,
   );

   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
   };

   const handleClearSearch = (): void => {
      setSearchQuery('');
   };

   const employeeList =
      searchQuery.trim() && searchResults.length > 0
         ? searchResults.map((result) => result.entity) // Show search results if a query exists
         : filteredSortedEmployeeList; // Show the default employee list if no query

   useEffect(() => {
      if (reloadRecent) {
         customerRecentRefresh();
         setReloadRecent(false);
      }
   }, [reloadRecent, customerRecentRefresh]);

   return (
      <div className="affiliate-attorney">
         {/* Tabs */}
         {!load?.userId && (
            <div className="tab-container">
               <div className="tab">
                  <button
                     className={`tab-button ${activeTab === 'Clients' ? 'active' : ''}`}
                     onClick={() => setActiveTab('Clients')}
                  >
                     Clients
                  </button>
                  <button
                     className={`tab-button ${activeTab === 'Employees' ? 'active' : ''}`}
                     onClick={() => setActiveTab('Employees')}
                  >
                     Employees
                  </button>
               </div>
            </div>
         )}

         {/* Content based on active tab */}
         {activeTab === 'Clients' && (
            <>
               <Search
                  placeholderPrimary={'for an Active Client '}
                  placeholderSecondary={'by name or customer ID'}
                  searchQueryAtom={customersSearchQueryAtom}
                  searchResultSelector={searchedCustomersSelector}
                  searchById={true}
                  resultPageLink={'client-details'}
                  customerRecents={customerRecents}
                  customers={customers}
                  disabled={false}
               />
               <SortFilter disabled={false} />

               <div className="content-wrapper">
                  <div className="attorney-content">
                     <div className="attorney-view-header">
                        <div className="btn-wrapper">
                           <InviteButton
                              inviteTarget="Client"
                              headerText="Invite a New Client"
                              bodyText="Inviting a new client will send a release for information (ROI) form to your client's email. Once they sign the ROI, they will be added to your client list."
                              confirmText="Send ROI Request"
                              successTextPre="ROI sent to "
                              successTextPost=" email. Once they have signed, they will be added to your client list and tagged with “New Client”."
                              inviteFunction={api.postInviteCustomer}
                           />
                        </div>
                        <CheckboxText
                           text="Show archive"
                           checkboxState={customersArchiveAtom}
                        />
                     </div>

                     <div className="tab-content">
                        {filteredSortedList.map((customer) => (
                           <CardCustomer
                              key={customer.id}
                              customer={customer}
                           />
                        ))}
                     </div>
                  </div>
               </div>
            </>
         )}

         {activeTab === 'Employees' && (
            <>
               <GeneralSearch
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="for a Employee "
                  placeholderSecondary="by name or ID"
                  clearable={true}
                  onClear={handleClearSearch}
               />

               <EmployeeSort />

               <div className="content-wrapper">
                  <div className="attorney-content">
                     <div className="btn-wrapper">
                        <InviteButton
                           inviteTarget="Employee"
                           headerText="Add New Employee"
                           bodyText="Adding a new employee will send an invitation to their email to join your network."
                           confirmText="Save"
                           successTextPre="Invitation sent to "
                           successTextPost=" email. Once they accept, they will be added to your employee list."
                           inviteFunction={(invite) => {
                              if (affiliate?.id) {
                                 return api.postAddEmployee(
                                    invite,
                                    affiliate.id,
                                 );
                              } else {
                                 console.error(
                                    'Affiliate ID is not available.',
                                 );
                                 return Promise.resolve(null); // Or handle accordingly
                              }
                           }}
                        />
                     </div>

                     <div className="tab-content">
                        {employeeList.length > 0 ? (
                           employeeList.map((employee) => (
                              <CardEmployee
                                 key={employee.id}
                                 employee={employee}
                              />
                           ))
                        ) : (
                           <CardEmployee employee={null} />
                        )}
                     </div>
                  </div>
               </div>
            </>
         )}
      </div>
   );
}
