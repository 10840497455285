import React from 'react';
import AppHeader from '../components/app-header/app-header';
import AppFooter from '../components/app-footer/app-footer';
import FirmDetails from '../components/firm-details/firm-details';
import FirmPaging from '../components/firm-paging/firm-paging';
import GeneralSearch from '../components/general-search/general-search';
import FirmSortFilter from '../components/firm-sort-filter/firm-sort-filter';
import { firmPagingAtom } from '../recoil/firm-paging';
import { useRecoilValue } from 'recoil';
import { filteredFirmSelector } from '../recoil/firm-sort-filter';
import './firmInfo.scss';

export default function FirmInfoPage(): JSX.Element {
   const [searchQuery, setSearchQuery] = React.useState('');
   const firm = useRecoilValue(filteredFirmSelector);
   const currentPage: number = useRecoilValue(firmPagingAtom);

   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
   };

   const handleClearSearch = () => {
      setSearchQuery('');
   };

   const filteredFirms = firm.filter((info) =>
      info.organizationName.toLowerCase().includes(searchQuery.toLowerCase()),
   );

   return (
      <div className="firm">
         <div className="app-body">
            <AppHeader overrideName={false} />

            <GeneralSearch
               value={searchQuery}
               onChange={handleSearchChange}
               placeholder="for a Firm "
               placeholderSecondary="by name"
               clearable={true}
               onClear={handleClearSearch}
            />

            <FirmSortFilter />

            <div className="content-row-wrapper">
               <div className="firm-content">
                  {filteredFirms
                     .slice(0 + 25 * currentPage, 25 + 25 * currentPage)
                     .map((info) => (
                        <FirmDetails key={info.organizationName} firm={info} />
                     ))}
                  <FirmPaging />
               </div>
            </div>
         </div>
         <AppFooter />
      </div>
   );
}
