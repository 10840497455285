import { atom, selector } from 'recoil';
import { IAffiliateShort, Sort, SortDirection } from '../lib/types';
import { adminAffiliatesSelector, organizationNameAtom } from './admin';
import { adminFirmFiltersAtom } from './admin-firm-search';
import { sortDirectionAtom } from './customers-sort-filter';
import { adminCityFiltersAtom } from './admin-city-search';
import { adminStateFiltersAtom } from './admin-state-search';

// currently applied sort
export const adminSortAtom = atom<Sort>({
   key: 'AdminAffiliateListSort',
   default: Sort.default,
});

// Common selector for filtering and sorting
export const filteredSortedAffiliatesSelector = selector({
   key: 'FilteredSortedAdminAffiliateList',
   get: ({ get }): IAffiliateShort[] => {
      const firmFilters = get(adminFirmFiltersAtom);
      const cityFilters = get(adminCityFiltersAtom);
      const stateFilters = get(adminStateFiltersAtom);
      const sort = get(adminSortAtom);
      const direction = get(sortDirectionAtom);
      let affiliates = get(adminAffiliatesSelector) || [];

      // Apply filtering based on firm, city, and state
      if (firmFilters.length) {
         affiliates = affiliates.filter(a =>
            firmFilters.some(f => f.toLowerCase() === a.organizationName.toLowerCase())
         );
      }
      if (cityFilters.length) {
         affiliates = affiliates.filter(a =>
            cityFilters.some(f => f.toLowerCase() === a.organizationCity.toLowerCase())
         );
      }
      if (stateFilters.length) {
         affiliates = affiliates.filter(a =>
            stateFilters.some(f => f.toLowerCase() === a.organizationState.toLowerCase())
         );
      }

      // Apply sorting
      let sortedAffiliates = [...affiliates];
      if (sort === Sort.firstName) {
         sortedAffiliates.sort((a, b) => a.firstName.localeCompare(b.firstName));
      } else if (sort === Sort.lastName) {
         sortedAffiliates.sort((a, b) => a.lastName.localeCompare(b.lastName));
      }

      // Reverse if sorting direction is descending
      return direction === SortDirection.ascending ? sortedAffiliates : sortedAffiliates.reverse();
   },
});
