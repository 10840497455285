import Fuse from 'fuse.js';
import { atom, selector } from 'recoil';
import { adminAffiliatesSelector } from './admin';
import { filteredSortedAffiliatesSelector } from './admin-sort-filter';

// currently applied search
export const adminStateSearchQueryAtom = atom<string>({
   key: 'AdminStateSearchQuery',
   default: '',
});

// searches affiliates state list based on adminStateSearchQueryAtom
export const searchedStateSelector = selector({
   key: 'SearchedStateList',
   get: ({ get }): string[] => {
      const query = get(adminStateSearchQueryAtom);
      const affiliates = get(filteredSortedAffiliatesSelector) || [];
      const searchResults: string[] = [];
      const lowercaseSet = new Set();

      const options = {
         includeScore: true,
         threshold: 0.5,
         keys: ['organizationState'],
      };

      const fuse = new Fuse(affiliates, options);
      const results = fuse.search(query);

      results.slice(0, 6).forEach((result) => {
         const orgState = result.item.organizationState;
         const lowercaseOrgState = orgState.toLowerCase();

         if (!lowercaseSet.has(lowercaseOrgState)) {
            lowercaseSet.add(lowercaseOrgState);
            searchResults.push(orgState);
         }
      });

      return searchResults;
   },
});

// currently applied state filters
export const adminStateFiltersAtom = atom<string[]>({
   key: 'AdminStateFilters',
   default: [],
});
