import {
   SerializableParam,
   atom,
   atomFamily,
   selector,
   selectorFamily,
} from 'recoil';
import api from '../lib/api';
import { IAffiliate, IAffiliateShort, ICustomer, IEmployee } from '../lib/types';

export const organizationNameAtom = atom<string | null>({
   key: 'organizationNameAtom',
   default: null,
});

export const adminAffiliatesSelector = selector({
   key: 'adminAffiliatesSelector',
   get: async ({ get }): Promise<IAffiliateShort[]> => {
      const organizationName = get(organizationNameAtom);

      // If organizationName is not set, return an empty array immediately
      if (!organizationName) return [];

      // Fetch affiliates based on organizationName
      const affiliates: IAffiliateShort[] | null = await api.getAdminAffiliates(organizationName);

      if (!affiliates) return [];

      // Concatenate first and last names
      affiliates.forEach((affiliate) => {
         affiliate.name = `${affiliate.firstName} ${affiliate.lastName}`;
      });

      return affiliates;
   },
});

export const adminAffiliatesAtom = atom<IAffiliateShort[]>({
   key: 'adminAffiliatesAtom',
   default: adminAffiliatesSelector,
});

export const adminAffiliateAtomFamily = atomFamily<
   IAffiliate | null,
   SerializableParam
>({
   key: 'adminAffiliateAtomFamily',
   default: selectorFamily({
      key: 'adminAffiliateSelector',
      get: (affiliateId) => async () => {
         const affiliate = await api.getAdminAffiliate(Number(affiliateId));
         return affiliate;
      },
   }),
});

export const adminAffiliateCustomersAtomFamily = atomFamily<
   ICustomer[],
   SerializableParam
>({
   key: 'adminAffiliateCustomersAtom',
   default: selectorFamily({
      key: 'adminAffiliateCustomersSelector',
      get: (affiliateId) => async () => {
         const customers = await api.getAdminAffiliateCustomers(
            Number(affiliateId)
         );
         return customers != null ? customers : [];
      },
   }),
});

export const adminAffiliateEmployeesAtomFamily = atomFamily<
   IEmployee[],
   SerializableParam
>({
   key: 'adminAffiliateEmployeesAtom',
   default: selectorFamily({
      key: 'adminAffiliateEmployeesSelector',
      get: (affiliateId) => async () => {
         const employees = await api.getAdminAffiliateEmployees(
            Number(affiliateId)
         );
         return employees != null ? employees : [];
      },
   }),
});
